.try-button {
  transition: 500ms;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #00cf9d;

  box-shadow: 2px 2px 10.3px 0px rgba(107, 240, 192, 0.5);

  color: #fff;
  border: 2px solid #00cf9d;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  &:hover {
    background-color: transparent;
    color: #00cf9d;
  }
}

button {
  margin: 0;
}

