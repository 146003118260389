img {
  object-fit: contain;
}
.App {
  height: 100vh;
  text-align: center;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;

  @media (max-width: 950px) {
    min-height: 100vh;

    button {
      margin-bottom: 20px;
    }
  }
}

.App > * {
  flex: 1;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  margin-bottom: 0;
}

.logo {
  width: clamp(300px, 50%, 300px);
  margin-top: 50px;
}

.App-main {
  max-width: 1200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-bottom: 25px;
  font-size: clamp(24px, 5vw, 46px);
  max-width: 1000px;
  font-family: Inter, sans-serif;
  color: #035c84;
  margin: 3vh 20px;
}

.frame {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transform: translateX(3%);
}
.desktop {
  width: clamp(340px, 80%, 1000px);
  transform: translateX(2%) !important;
}

.try-button {
  cursor: pointer;
  transition: 500ms;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: #00cf9d;
  box-shadow: 2px 2px 10.3px 0px rgba(107, 240, 192, 0.5);
  color: #fff;
  border: 2px solid #00cf9d;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 2vh 0;

  &:hover {
    background-color: transparent;
    color: #00cf9d;
  }
}

@media screen and (max-width: 900px) {
  .App {
    justify-content: start;
    gap: 4vh;
  }
  .App > * {
    flex: 0;
  }
}

@media screen and (max-height: 750px) {
  .App {
    overflow-y: scroll;
  }
  
}
