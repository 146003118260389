body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  width: 100vw;
  overflow: hidden;
  box-sizing: content-box;
  font-family: 'Montserrat', sans-serif;
  
}
html{
  overflow-y: scroll;
  min-height: 100svh;
}

h1 , h2 , h3  {
  font-family: 'Inter', sans-serif;
  
}

h1{
  font-size: clamp(32px, 3vw, 48px);
}

h2{
  font-size: clamp(28px, 3vw, 42px);
  margin: 40px 0;
}
h3{
  font-size: clamp(18px, 3vw, 24px);
  margin: 20px 0;
}

p{
  font-size: clamp(16px, 3vw, 18px);
}